import { parseISO } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { isString } from 'lodash'

export function formatDateInUTC(date: Date, fmt: string): string {
  return format(utcToZonedTime(date, 'UTC'), fmt, { timeZone: 'UTC' })
}

export function formatDateStringInUTC(dateString: string, fmt: string): string {
  const date = new Date(dateString)
  return formatDateInUTC(date, fmt)
}

export const formatReportingDate = (dateString: string) => {
  if (!isString(dateString)) {
    return ''
  }

  const date = parseISO(dateString)
  const formattedDate = format(date, 'MMM d, yyyy')

  return formattedDate
}

export const formatReportingMonth = (dateString: string) => {
  if (!isString(dateString)) {
    return ''
  }

  const date = parseISO(dateString)
  const formattedDate = format(date, 'MMMM yyyy')

  return formattedDate
}

export function formatFullDate(date: Date | number | string, withTime = false): string {
  date = typeof date === 'string' ? parseISO(date) : new Date(date)

  const dateFormat = isCurrentYear(date) ? 'MMM d' : 'MMM d, yyyy'
  const timeFormat = withTime ? ', h:mmaaa' : ''

  return format(date, `${dateFormat}${timeFormat}`)
}

function isCurrentYear(date: Date): boolean {
  return date.getFullYear() === new Date().getFullYear()
}
