import { createContext, ReactNode, useEffect, useRef, useState } from 'react'
import { Role } from 'api/dto'
import { axios } from 'api/lib'
import { useSession } from 'auth'
import { InternalAxiosRequestConfig } from 'axios'
import { useLocalStorage } from 'usehooks-ts'
import { updateUser, UpdateUserRequest } from '../users'

export type AmityContextValue = {
  isAuthorized?: boolean
  deviceId?: string
  activateProfile?: (perryUserId: number, amityUserData: UpdateUserRequest) => Promise<void>
}

export const AmityContext = createContext<AmityContextValue>({})

export function AmityProvider({ children }: { children: ReactNode }) {
  const deviceId = useDeviceId()
  const { accessToken, activateProfile } = useAccessToken(deviceId)
  const isAuthorized = useAmityAuthInterceptor(accessToken)

  return (
    <AmityContext.Provider
      value={{
        isAuthorized,
        activateProfile,
        deviceId,
      }}
    >
      {children}
    </AmityContext.Provider>
  )
}

function useAccessToken(deviceId: string) {
  const [accessToken, setAccessToken] = useState<string | undefined>()
  const initialized = useRef<boolean>(false)
  const { auth, user, socialProfileNotActivated } = useSession()

  const isLoginPostponed =
    !auth.isAuthenticated || // user is not authenticated
    !auth.isInitialized || // session data is not loaded
    !user || // user is not created (first signup step)
    !user.roles.includes(Role.INVESTOR) || // user is in signup flow or admin
    socialProfileNotActivated // user finished signup flow but didn't confirm profile data

  useEffect(() => {
    if (isLoginPostponed || initialized.current || !deviceId) {
      return
    }

    initialized.current = true // Prevent multiple calls

    getAmitySession(deviceId).then((amitySession) => {
      setAccessToken(amitySession.accessToken)
    })
  }, [isLoginPostponed, initialized.current, deviceId])

  // should be used only once after user is pass profile confirmation step
  const activateProfile = async (perryUserId: number, amityUserData: UpdateUserRequest) => {
    if (initialized.current || !deviceId) {
      return
    }

    initialized.current = true // Prevent multiple calls

    // fetch amity session first time and set access token state
    const amitySession = await getAmitySession(deviceId)
    setAccessToken(amitySession.accessToken)

    // mark social profile as activated and add user to all default groups
    await axios.post(`/api/users/${perryUserId}/social-profile/activate`)

    // update user data with data from social profile
    await updateUser(amityUserData, {
      headers: { Authorization: `Bearer ${amitySession.accessToken}` },
    })
  }

  return { accessToken, activateProfile }
}

function useDeviceId(): string {
  const [deviceId, setDeviceId] = useLocalStorage('amity-deviceId', crypto.randomUUID())
  useEffect(() => setDeviceId(deviceId), [deviceId])
  return deviceId
}

function useAmityAuthInterceptor(accessToken?: string) {
  const [interceptorIsReady, setInterceptorIsReady] = useState<boolean>()

  useEffect(() => {
    if (!accessToken) {
      return
    }

    const appendAmityAuthHeader = (config: InternalAxiosRequestConfig) => {
      if (
        process.env.NEXT_PUBLIC_AMITY_API_URL &&
        config.baseURL?.startsWith(process.env.NEXT_PUBLIC_AMITY_API_URL) &&
        !config.headers?.Authorization // Do NOT override header if it's explicitly provided.
      ) {
        config!.headers!.Authorization = `Bearer ${accessToken}`
      }
      return config
    }

    const authInterceptorId = axios.interceptors.request.use(appendAmityAuthHeader)
    setInterceptorIsReady(true)

    return () => {
      setInterceptorIsReady(false)
      axios.interceptors.request.eject(authInterceptorId)
    }
  }, [accessToken])

  return interceptorIsReady
}

/**
 * Registers Amity session for the current user and given device ID.
 */
async function getAmitySession(deviceId: string): Promise<{ accessToken: string }> {
  const { data } = await axios.post('/api/amity/session', { deviceId })
  return data
}
