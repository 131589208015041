export enum FundType {
  CLOSED_END = 'CLOSED_END',
  OPEN_END = 'OPEN_END',
  DIRECT_DEAL = 'DIRECT_DEAL',
}

export interface InvestmentEntity {
  id: number
  name: string
}

export interface MetricsSummary {
  totalNetAssetsValue: number
  totalInvestedCapital: number
  totalCommittedCapital: number
  totalRemainingUncalledCapital: number
}

export interface EntityMetrics {
  investedEntity: string
  investedEntityId: number
  fundType: FundType
  currentBalance: number
  investedCapital: number
  committedCapital?: number
  calledPercent?: number
  remainingUncalled?: number
  uncalledPercent?: number
}

export interface DetailedMetrics {
  assetClass: string
  fundName: string
  fundId: number
  investedEntity: string
  investedEntityId: number
  fundType: FundType
  shareClass: string
  shareClassId: number
  asOfDate: string
  currentBalance: number
  investedCapital: number
  additionalMetrics: ClosedEndFundMetrics | OpenEndFundMetrics | DirectDealMetrics
}

export interface ClosedEndFundMetrics {
  fundType: 'CLOSED_END'
  committedCapital: number
  calledCapitalPercent: number
  distributions: number
  DPI: number
  TVPI: number
  MOIC: number
  IRR: number
}

export interface OpenEndFundMetrics {
  fundType: 'OPEN_END'
  redemptions: number
  YTD: number
  ITD: number
}

export interface DirectDealMetrics {
  fundType: 'DIRECT_DEAL'
  MOIC: number
  IRR: number
}
