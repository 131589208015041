import { FC } from 'react'
import { FieldError, Control, Controller } from 'react-hook-form'
import clsx from 'clsx'
import { Label, Error } from 'ui/src/components/forms'
import { SelectAutocomplete, SelectAutocompleteProps } from './SelectAutocomplete'

export interface SelectAutocompleteField
  extends Omit<SelectAutocompleteProps, 'value' | 'onChange' | 'hasError'> {
  readonly className?: string
  readonly disabled?: boolean
  readonly name: string
  readonly label: string | JSX.Element
  readonly error?: FieldError
  readonly control: Control<any>
  readonly valueToSelected?: (value: string) => any
  readonly onChange?: (selected: any) => void
}

export const SelectAutocompleteField: FC<SelectAutocompleteField> = ({
  className,
  disabled,
  name,
  label,
  error,
  control,
  valueToSelected,
  onChange: externalOnChange,
  ...props
}) => {
  return (
    <div className={clsx({ 'opacity-25': disabled }, className)} data-testid={`combobox-${name}`}>
      {label && <Label name={name}>{label}</Label>}

      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => {
          return (
            <SelectAutocomplete
              className="mt-1"
              value={valueToSelected ? valueToSelected(value || null) : value || null}
              onChange={(value) => (externalOnChange ? externalOnChange(value) : onChange(value))}
              isOptionDisabled={(item) => value?.id === item.id}
              hasError={!!error}
              {...props}
            />
          )
        }}
      />

      <Error className="mt-1 h-5" id={`${name}-error`} data-testid={`${name}-error`}>
        {error?.message}
      </Error>
    </div>
  )
}
